.ewh-footer {
  background-color: $ewh-footer-background;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  min-height: 160px;
  text-align: center;
  width: 100%;

  .footer-content {
    color: $text-color-1;
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media #{$bp-large} {
      flex-flow: row;
    }

    a {
      color: $text-color-1;
      text-decoration: none;
    }

    @media #{$bp-medium} {
      max-width: 738px;
    }

    @media #{$bp-large} {
      max-width: 960px;
    }

    @media #{$bp-extra-large} {
      max-width: 1140px;
    }
  }

  .social {
    align-items: baseline;
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{$bp-large} {
      align-items: center;
      justify-content: flex-end;
      padding-left: 4rem;
      width: auto;
    }

    a {
      font-size: 48px;
      margin-right: 2rem;
    }
  }

  .footer-menu-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  .footer-menu-wrapper {
    flex-flow: column;
  }

  .footer-menu-top-wrapper {
    align-items: flex-end;
    display: flex;
    flex-grow: 1;
  }

  .footer-menu-bottom-wrapper {
    align-items: flex-start;
    display: flex;
    flex-grow: 1;
  }

  .footer-menu {
    align-items: center;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
    width: 100%;

    @media #{$bp-large} {
      justify-content: flex-start;
    }

    li {
      float: left;

      @media #{$bp-large} {
        font-size: 20px;
        margin-right: 1.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 16px;

        @media #{$bp-large} {
          font-size: 20px;
        }
      }
    }
  }
}
