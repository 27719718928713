.image-gallery {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .image-overlay {
    background-color: $area-color-6-40;
    display: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2;

    p {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 0.5rem;
      display: inline-block;
      font-size: 1rem;
      line-height: 1.25rem;
      margin: 0.25rem 0 0;
      padding: 0.5rem;
    }

    .img-container {
      color: $text-color-1;
      left: 50%;
      max-width: 90vw;
      position: fixed;
      top: 50%;
      transform: translate(-50%, -50%);

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-height: 90vh;
        max-width: 90vw;
      }
    }
  }

  .arrow {
    color: $text-color-1;
    cursor: pointer;
    font-size: 35px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;

    &.left {
      left: 30px;
    }

    &.right {
      right: 30px;
    }

    &.hidden {
      display: none;
    }
  }

  .image {
    flex-basis: calc(50% - 10px);
    margin: 0 10px 10px 0;
    overflow: hidden;
    position: relative;

    @media #{$bp-medium} {
      flex-basis: calc(33% - 10px);
    }

    @media #{$bp-large} {
      cursor: pointer;
      flex-basis: calc(50% - 10px);
    }

    @media #{$bp-extra-large} {
      flex-basis: calc(33% - 10px);
    }

    &:hover {
      .overlay {
        @media #{$bp-large} {
          display: block;
        }
      }
    }

    img {
      width: 100%;
    }

    i {
      bottom: 5px;
      color: $text-color-2;
      font-size: 20px;
      position: absolute;
      right: 5px;

      @media #{$bp-large} {
        display: none;
      }
    }
  }

  .overlay {
    background: $area-color-6-40;
    color: $text-color-1;
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    i {
      color: $text-color-1;
      display: block;
      position: relative;
      text-align: center;
      top: 50%;
    }
  }
}
