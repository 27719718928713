@font-face {
  font-display: fallback;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 500;
  src: local('Myriad Pro Regular'),
    url('../static/MYRIADPRO-REGULAR.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Myriad Pro Light'),
    url('../static/MyriadPro-Light.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 600;
  src: local('Myriad Pro Semibold'),
    url('../static/MYRIADPRO-SEMIBOLD.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Myriad Pro';
  font-style: italic;
  font-weight: 600;
  src: local('Myriad Pro Semibold Italic'),
    url('../static/MYRIADPRO-SEMIBOLDIT.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Myriad Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Myriad Pro Bold'),
    url('../static/MYRIADPRO-BOLD.woff') format('woff');
}

@font-face {
  font-display: fallback;
  font-family: 'Myriad Pro';
  font-style: italic;
  font-weight: 700;
  src: local('Myriad Pro Bold Italic'),
    url('../static/MYRIADPRO-BOLDIT.woff') format('woff');
}
