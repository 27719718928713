.headline {
  padding: 0 25px 25px;

  @media #{$bp-medium} {
    padding: 0 0 25px;
  }

  h1 {
    color: $text-color-3;
    font-family: 'Myriad Pro Black';
    font-size: 35px;
    font-weight: 500;
    margin-bottom: 0;

    @media #{$bp-medium} {
      font-size: 50px;
    }
  }
}
