.module.ewh-side-by-side {
  display: flex;

  .image {
    flex: 0 1 100%;

    &:first-child {
      margin-right: 1rem;
    }

    &:last-child {
      margin-left: 1rem;
    }

    img {
      display: block;
      width: 100%;
    }
  }
}
