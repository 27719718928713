.footer {
  background-color: $area-color-3;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  min-height: 160px;
  text-align: center;
  width: 100%;

  .footer-content {
    color: $text-color-1;
    display: flex;
    flex-flow: row wrap;
    margin-left: auto;
    margin-right: auto;
    width: 100%;

    @media #{$bp-large} {
      flex-flow: row;
    }

    a {
      color: $text-color-1;
      text-decoration: none;
    }

    @media #{$bp-medium} {
      max-width: 738px;
    }

    @media #{$bp-large} {
      max-width: 960px;
    }

    @media #{$bp-extra-large} {
      max-width: 1140px;
    }
  }

  .copyright {
    margin: 1em;
    text-align: left;

    @media #{$bp-large} {
      padding-left: 2rem;
    }
  }

  .social {
    @media #{$bp-large} {
      padding-left: 4rem;
    }
  }

  .social,
  .copyright {
    align-items: baseline;
    display: flex;
    justify-content: center;
    width: 100%;

    @media #{$bp-large} {
      align-items: center;
      justify-content: flex-end;
      width: auto;
    }

    a {
      font-size: 48px;
      margin-right: 2rem;
    }
  }

  .footer-menu-wrapper {
    display: flex;
    flex-flow: column;
    flex-grow: 1;
  }

  .footer-menu-wrapper {
    flex-flow: column;
  }

  .footer-menu-top-wrapper {
    display: flex;
    flex-grow: 1;
  }

  .footer-menu {
    align-items: center;
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    padding: 0;
    width: 100%;

    @media #{$bp-large} {
      justify-content: flex-start;
    }

    li {
      float: left;

      @media #{$bp-large} {
        font-size: 20px;
        margin-right: 1.5rem;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        font-size: 16px;

        @media #{$bp-large} {
          font-size: 20px;
        }
      }
    }
  }
}
