.text-bild {
  margin-bottom: 50px;

  p {
    font-family: Helvetica, Arial, Sans-Serif !important;
    font-size: 16px !important;
    line-height: 28px;
    margin: 0;
  }

  .text-double {
    @media #{$bp-medium} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    div {
      @media #{$bp-medium} {
        width: calc((100% - 20px) / 2);
      }
    }

    .left {
      margin-bottom: 20px;

      @media #{$bp-medium} {
        margin-bottom: 0;
      }
    }
  }

  .text-image {
    display: flex;
    flex-direction: column;
    @media #{$bp-medium} {
      display: inline-block;
      width: 100%;
    }

    p {
      order: 2;
    }

    .img-container {
      margin-bottom: 18px;
      order: 1;
      width: 100%;

      @media #{$bp-medium} {
        float: left;
        margin: 0 20px 20px 0;
        width: calc(50% - 10px);
      }

      img {
        max-width: 100%;
      }
    }

    &.right {
      .img-container {
        @media #{$bp-medium} {
          float: right;
          margin: 0 0 20px 20px;
        }
      }
    }
  }

  .image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$bp-medium} {
      @include flex-spacing(20px);
    }

    div.image-wrap {
      margin-bottom: 25px;
      width: 100%;
      @media #{$bp-medium} {
        flex-basis: 100%;
        flex-shrink: 1;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    @media #{$bp-medium} {
      flex-flow: row nowrap;
    }
  }
}
