@mixin flex-spacing($gap) {
  > * {
    margin-left: $gap / 2;
    margin-right: $gap / 2;
  }

  > *:first-child {
    margin-left: 0;
    margin-right: $gap / 2;
  }

  > *:last-child {
    margin-left: $gap / 2;
    margin-right: 0;
  }

  > *:first-child:last-child {
    margin-left: 0;
    margin-right: 0;
  }
}
