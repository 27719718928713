.ewh-quick-login {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  margin-top: 20vh;
  max-width: 800px;

  .error {
    color: $validation-color;
  }
}
