.cookie-banner {
  background: rgba(86, 86, 86, 0.95);
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  left: 0;
  padding: 7px 27px;
  position: fixed;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.35);
  top: 0;
  width: calc(100vw - 54px);
  word-break: normal;
  z-index: 2000;

  a {
    color: #fff;
    text-decoration: underline;
  }

  button {
    background: $area-color-8;
    border: 0;
    border-radius: 3px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
    color: #fff;
    display: inline-block;
    margin: 0 5px;
    padding: 0.5em 0.75em;
    text-decoration: none;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.35);
  }
}
