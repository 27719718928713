.header {
  margin-top: 50px;
  position: relative;

  @media #{$bp-large} {
    margin-top: 70px;
  }

  .slides {
    display: flex;
    overflow: hidden;
  }

  .slide {
    display: block;
    flex: 0 0 100%;
    overflow: hidden;
    position: relative;
    transition: transform 1s;

    img {
      height: 100%;
      width: auto;

      @media #{$bp-large} {
        height: auto;
        width: 100%;
      }
    }
  }

  .content-container {
    display: flex;

    &.right {
      justify-content: flex-end;
    }

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }
  }

  .info-box {
    border-radius: 5px;
    bottom: 80px;
    font-family: $font-family-1;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin: 0 auto;
    padding: 20px;
    position: absolute;
    width: 80%;

    @media #{$bp-small} {
      bottom: 90px;
      max-width: 410px;
      max-width: 300px;
    }

    @media #{$bp-medium} {
      bottom: 90px;
      max-width: 300px;
    }

    @media #{$bp-extra-large} {
      bottom: 15%;
    }

    a {
      color: inherit;
      text-decoration: underline;
    }

    h3 {
      margin-top: 0;
    }

    &.color-1 {
      background-image: linear-gradient(
        to right,
        $area-color-1-90,
        lighten($area-color-1-90, 5%)
      );
    }

    &.color-2 {
      background-image: linear-gradient(
        to right,
        $area-color-5-90,
        lighten($area-color-5-90, 5%)
      );
      color: $text-color-1;

      button {
        background-color: $area-color-2;
        box-shadow: $area-color-6 0 8px 6px -6px;
        color: $text-color-2;
      }
    }

    &.color-3 {
      background-image: linear-gradient(
        to right,
        $area-color-2-90,
        lighten($area-color-2-90, 5%)
      );

      button {
        box-shadow: $area-color-6 0 8px 6px -6px;
      }
    }

    &.color-4 {
      background-image: linear-gradient(
        to right,
        $area-color-3-90,
        lighten($area-color-3-90, 5%)
      );
      color: $text-color-1;

      button {
        background-color: $area-color-2;
        box-shadow: $area-color-6 0 8px 6px -6px;
        color: $text-color-2;
      }
    }
  }

  .indicator-group {
    bottom: 15px;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  .indicator {
    background-color: $area-color-1;
    border-radius: 12px;
    cursor: pointer;
    height: 12px;
    margin: 0 4px;
    opacity: 0.5;
    width: 12px;

    &.active {
      opacity: 1;
    }
  }

  .copyright {
    background-color: $area-color-6-40;
    bottom: 5px;
    padding: 3px;
    position: absolute;
    right: 0;

    a {
      color: $text-color-1;
    }
  }
}
