.download {
  background-color: $area-color-4;
  margin: 20px 25px 56px;

  @media #{$bp-large} {
    margin: 20px 0 56px;
    width: 350px;
  }

  .box-headline {
    background-color: $area-color-5;
    color: $text-color-1;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding-left: 17px;
  }

  .file {
    border-bottom: 1px solid $area-color-1;
    padding: 14px 35px 14px 17px;
    position: relative;

    a {
      color: $text-color-3;
      display: block;
      margin-top: 3px;
      text-decoration: none;
    }
  }

  .download-icon {
    font-size: 25px;
    position: absolute;
    right: 25px;
    top: 15px;
  }
}
