.right-column {
  .iframe {
    background-color: $area-color-4;
    margin: 20px 25px 56px;
    width: auto;

    @media #{$bp-large} {
      margin: 20px 0 56px;
      width: 350px;
    }

    p {
      padding: 0 17px 17px;
    }
  }
}

.iframe {
  font-family: Helvetica, Arial, Sans-Serif !important;
  font-size: 14px;
  line-height: 22px;
}
