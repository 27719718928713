.left-column {
  .color-content {
    margin: 20px auto 0;

    @media #{$bp-large} {
      margin-left: -70px;
      padding: 0 35px 0 70px;
    }
  }
}

.color-content {
  font-family: $font-family-1;
  font-size: 16px;
  font-weight: 300;
  line-height: 28px;
  margin: 0;

  &.color-2 {
    background-color: $area-color-3;
    color: $text-color-1;

    button {
      background-color: $area-color-2;
      box-shadow: $area-color-6 0 8px 6px -6px;
      color: $text-color-2;
    }
  }

  &.color-3 {
    background-color: $area-color-2;

    button {
      box-shadow: $area-color-6 0 8px 6px -6px;
    }
  }

  &.color-4 {
    background-color: $area-color-5;
    color: $text-color-1;

    button {
      background-color: $area-color-2;
      box-shadow: $area-color-6 0 8px 6px -6px;
      color: $text-color-2;
    }
  }

  .module-content {
    @media #{$bp-medium} {
      padding: 1px 0 25px;
    }
  }

  h2 {
    font-size: 16px;
    font-style: italic;
    font-weight: normal;
  }
}
