.navigation-container {
  background-color: $area-color-1;
  height: 50px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;

  @media #{$bp-large} {
    height: 70px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &.scroll {
    box-shadow: 0 -10px 25px 10px $area-color-6-50;
  }

  .logo {
    height: 100%;
    position: absolute;
    width: 100vw;
    z-index: 1;

    .module {
      height: 100%;
      padding-top: 0;
    }

    img {
      height: 100%;
    }
  }
}

.navigation {
  color: $text-color-2;
  float: right;
  position: fixed;
  right: 35px;
  z-index: 2;

  &.desktop {
    display: none;
    @media #{$bp-large} {
      display: block;
    }

    .menu {
      display: flex;
      margin-top: 0;

      .active {
        border-color: $area-color-3;
        font-weight: 700;
        padding-top: 16px;
      }
    }

    .first-level-item {
      border-top: 9px solid $transparent;
      cursor: pointer;
      display: inline;
      font-size: 14px;
      margin: 0 10px;
      padding: 17px 0 10px;
      position: relative;
      text-transform: uppercase;

      @media #{$bp-extra-large} {
        font-size: 16px;
      }

      &:first-child {
        display: flex;

        &:hover {
          border-color: $transparent;
        }

        i {
          border-right: 1px solid $area-color-1;

          font-size: 19px;
          padding-right: 17px;
        }
      }

      &.open {
        border-color: $area-color-3;

        .submenu {
          opacity: 1;
          visibility: visible;
        }
      }

      a,
      p {
        display: inline;
        padding-right: 2px;
      }
    }

    .submenu {
      background: $area-color-1;
      color: $text-color-2;
      cursor: normal;
      left: 0;
      min-width: 150px;
      opacity: 0;
      padding: 21px 17px 18px;
      position: absolute;
      top: 40px;
      transition: visibility 0s, opacity 0.3s linear;
      visibility: hidden;

      a {
        padding-right: 0;
      }
    }

    .second-level-item {
      border: 0;
      border-bottom: 2px solid $transparent;
      display: block;
      font-size: 16px;
      font-weight: 300;
      padding: 10px 0 0;
      text-transform: none;

      &:first-child {
        display: flex;

        &:hover {
          border-color: $area-color-3;
        }
      }

      &:hover {
        border-bottom: 2px solid $area-color-3;
      }
    }

    .search {
      display: none;
      left: 0;
      position: absolute;
      top: 46px;

      @media #{$bp-extra-large} {
        left: auto;
        right: 50px;
        top: 13px;
      }

      input {
        height: 20px;
        padding: 0 5px;
      }
    }

    .login {
      left: auto;
      padding: 31px 17px 18px;
      right: 0;
      text-transform: none;
      width: 180px;

      input {
        border: 0;
        height: 25px;
        padding: 1px 5px;
      }

      label {
        font-size: 16px;
      }

      button {
        background: $area-color-3;

        border: 0;
        color: $text-color-1;
        margin: 10px 0;
        padding: 5px;
      }

      a {
        color: $text-color-3;
        display: block;
        font-size: 15px;
        text-decoration: underline;
      }

      .form-check {
        align-items: center;
        display: flex;

        label {
          margin-left: 8px;
        }
      }
    }
  }

  &.mobile {
    right: 12px;
    top: 10px;
    @media #{$bp-large} {
      display: none;
    }

    &.open {
      .open-nav {
        opacity: 0;
      }

      .menu {
        left: -206px;
      }
    }

    .menu {
      background-color: $area-color-1;
      height: calc(100vh + 28px);
      left: 34px;
      overflow: auto;
      position: absolute;
      top: -28px;
      transition: left 0.3s ease-in-out;
      width: 240px;
    }

    .menu-popup {
      list-style-type: none;
      padding-left: 25px;
    }

    .first-level-item {
      border-bottom: 2px solid $area-color-3;
      font-size: 27px;
      margin: 9px 0 5px;
      padding: 10px 15px 10px 0;

      i {
        float: right;
      }
    }

    .control-icons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding: 13px 15px 0 0;

      i {
        color: $area-color-3;
        font-size: 23px;
      }
    }

    .search-close,
    .login-close {
      display: none;
    }

    input {
      height: 25px;
      margin: 10px 0;
      padding: 0 5px;
      width: calc(100% - 25px);
    }

    button {
      background-color: $area-color-3;

      border: 0;
      color: $text-color-1;
      padding: 7px 0;
      width: calc(100% - 12px);

      i {
        font-size: 10px;
      }
    }

    .search {
      &.open {
        .search-icon {
          display: none;
        }

        .search-close {
          display: block;
          font-size: 26px;
        }
      }
    }

    .login {
      &.open {
        .login-icon {
          display: none;
        }

        .login-close {
          display: block;
          font-size: 26px;
        }
      }
    }

    .search-item {
      display: block;
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-in-out;

      &.open {
        height: 90px;
      }
    }

    .login-item {
      display: block;
      height: 0;
      overflow: hidden;
      transition: height 0.3s ease-in-out;

      &.open {
        height: 180px;
      }

      a {
        color: $text-color-3;
        display: block;
        margin-top: 5px;
        text-decoration: underline;
      }
    }

    .submenu {
      list-style-type: none;
      overflow: hidden;
      padding-left: 20px;
      transition: height 0.3s ease-in-out;
    }

    .second-level-item {
      border-top: 1px solid $area-color-3;
      font-size: 20px;
      padding: 13px 0;
    }

    .open-nav {
      i {
        font-size: 25px;
      }
    }

    .first-level-item,
    .second-level-item {
      color: $text-color-2;
      margin: 0;
    }
  }
}
