article {
  > section {
    .form {
      input,
      select,
      textarea,
      .checkmark {
        background-color: $area-color-4;
      }

      .checkmark::before {
        color: $area-color-4;
      }
    }

    &.alternative-color {
      .form {
        input,
        select,
        textarea,
        .checkmark {
          background-color: $area-color-1;
        }

        .checkmark::before {
          background-color: $area-color-1;
        }
      }
    }
  }
}

.form {
  .one-column {
    input,
    textarea {
      width: calc(100% - 10px);
    }

    select {
      width: 100%;
    }
  }

  .two-column {
    input,
    select,
    textarea {
      width: calc(100% - 10px);

      @media #{$bp-medium} {
        width: calc(100% - 10px);
      }
    }

    select {
      width: 100%;

      @media #{$bp-medium} {
        width: 100%;
      }
    }

    div {
      @media #{$bp-medium} {
        display: inline-block;
        width: calc(50% - 17px);
      }

      &:first-child {
        @media #{$bp-medium} {
          margin-right: 30px;
        }
      }
    }
  }

  .dropdown,
  .text,
  .number,
  .mail,
  .date,
  .money,
  .textarea,
  .upload,
  .checkbox {
    margin-bottom: 21px;
    vertical-align: top;
  }

  input,
  select,
  textarea {
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    padding: 14px 0 14px 10px;

    &:focus {
      outline-color: $area-color-3;
    }
  }

  label {
    color: $text-color-2;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 6px;
    text-transform: uppercase;
  }

  textarea {
    height: 215px;
  }

  .date {
    input {
      appearance: textfield;
      text-transform: uppercase;
    }
  }

  .upload {
    input {
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      width: 0.1px;
      z-index: -1;
    }
  }

  .upload-label {
    cursor: pointer;

    span {
      color: $area-color-3;
      line-height: 40px;
      text-transform: none;
      vertical-align: bottom;
    }
  }

  .upload-add {
    background-color: $area-color-3;
    border-radius: 50%;
    color: $text-color-1;
    display: inline-block;
    height: 40px;
    margin-right: 20px;
    padding: 7px 0 0 12px;
    width: 40px;

    i {
      color: $text-color-1;
      font-size: 30px;
      margin: 1px 0 0 3px;
      transform: rotate(45deg);
    }

    &.selected {
      background-color: $area-color-3;

      i {
        transform: rotate(0deg);
      }
    }
  }

  .checkbox {
    input {
      display: inline-block;
      width: auto;
    }
  }

  .form-buttons {
    button {
      background-color: $area-color-1;
      border: 2px solid $area-color-3;
      border-radius: 5px;
      color: $text-color-2;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      height: 48px;
      margin: 0 5px 30px 0;
      text-transform: uppercase;
      width: 150px;

      @media #{$bp-medium} {
        margin: 0 30px 30px 0;
      }

      &:last-child() {
        margin: 0;
      }
    }

    .submit {
      background-color: $area-color-3;
      border: 0;
      color: $text-color-1;
    }

    span {
      float: right;
    }
  }

  .error {
    border: 2px solid $validation-color;
  }

  .error-text {
    color: $validation-color;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .info-line {
    text-align: right;
  }
}
