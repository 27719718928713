.members {
  margin-bottom: 30px;

  .hidden {
    display: none;
  }

  a {
    color: #9d153b;
    text-decoration: none;
  }
}
