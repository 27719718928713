.list {
  a {
    color: inherit;
    text-decoration: none;
  }

  .item {
    border-bottom: 1px solid $area-color-7;
    padding: 8px 0;
  }

  h1 {
    color: $text-color-3;
    font-size: 50px;
    font-style: italic;
    font-weight: 900;
    margin-top: 0;
  }

  .sle-overview {
    margin-bottom: 17px;
  }

  .date-time {
    color: $text-color-3;
    font-size: 16px !important;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 10px !important;
  }

  .headline-list {
    color: $text-color-2;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px !important;
  }

  .abstract {
    font-family: Helvetica, Arial, Sans-Serif !important;
    font-size: 16px !important;
    line-height: 28px;
  }

  .morelink {
    color: $text-color-3;
    font-family: Helvetica, Arial, Sans-Serif !important;
    font-size: 16px;
    line-height: 28px;
  }

  .list-nav {
    color: $text-color-3;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-top: 8px;
  }
}
