.contact {
  background-color: $area-color-4;
  margin: 20px 25px 56px;

  @media #{$bp-large} {
    margin: 20px 0 56px;
    width: 350px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .box-headline {
    background-color: $area-color-5;
    color: $text-color-1;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    padding-left: 17px;
  }

  .box-content-ap {
    align-items: start;
    border-bottom: 1px solid $area-color-1;
    display: flex;
    justify-content: flex-start;
    padding: 17px;

    @media #{$bp-large} {
      justify-content: space-between;
    }
  }

  .description {
    flex-basis: 202px;
    font-weight: 300;
    margin-left: 17px;

    @media #{$bp-large} {
      margin-left: 0;
    }
  }

  .bg-black {
    font-size: 18px;
    font-weight: 600;
  }

  .position {
    color: $text-color-3;
    margin-top: 20px;
  }
}
