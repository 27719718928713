body {
  font-family: $font-family-2;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.no-top-margin {
  @media #{$bp-large} {
    margin-top: 0;
  }
}

.module {
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 25px;

  @media #{$bp-medium} {
    max-width: 738px;
    padding: 20px 0;
    width: 100%;
  }

  @media #{$bp-large} {
    max-width: 960px;
  }

  @media #{$bp-extra-large} {
    max-width: 1110px;
  }
}

.module-full {
  margin: 20px auto 0;
  width: 100%;

  @media #{$bp-medium} {
    margin: 20px -15px 0;
    padding-left: 15px;
  }

  @media #{$bp-large} {
    margin: 20px auto 0;
    padding: 0;
  }

  .module-content {
    margin: 0 auto;
    padding: 35px 25px;

    @media #{$bp-medium} {
      max-width: 738px;
      padding: 80px 39px;
      padding: 0;
    }

    @media #{$bp-large} {
      max-width: 960px;
    }

    @media #{$bp-extra-large} {
      max-width: 1110px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media #{$bp-medium} {
    max-width: 738px;
    padding: 80px 39px;
    padding: 0;
  }

  @media #{$bp-large} {
    flex-direction: row;
    max-width: 960px;
  }

  @media #{$bp-extra-large} {
    max-width: 1110px;
  }
}

.left-column {
  @media #{$bp-large} {
    flex-grow: 1;
    padding: 0 35px 0 0;
  }
}

.right-column {
  @media #{$bp-large} {
    flex-basis: auto;
    padding: 0 0 0 35px;
    width: 450px;
  }
}

.link-button {
  background: $area-color-3;
  border: 0;
  box-shadow: $area-color-7 0 8px 6px -6px;
  color: $text-color-1;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px 25px;
  transition: box-shadow 0.5s linear;

  i {
    margin-left: 8px;
  }

  &:hover {
    box-shadow: 0 0 6px -6px !important;
    text-decoration: underline;
  }
}

.hidden {
  display: none !important;
}

a {
  color: $text-color-3;
  text-decoration: underline;
}
